<template lang="pug">
  div
    h1 404
</template>

<script>
export default {
  name: 'NotFound',
  components: {
  },
  metaInfo: {
    title: '404',
    titleTemplate: '%s - 臭豆腐 - 電影∣影集∣評分∣評論'
  },
  data(){
    return {
    }
  },
  async created(){
    
  }
}
</script>
